import { Component, OnInit } from '@angular/core';
import { ContentService, ContentUrls } from '../service/service.component';
import { Content } from '../service/service.component'
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  content;

  contentSubscription: Subscription;

  contentUrls;

  contentUrlsSubscription: Subscription;

  constructor(public service: ContentService) { }

  ngOnInit() {
    this.loadContent();
    this.loadContentUrls();
  }

  loadContent(): void {
    this.content = this.service.getContent();

    this.contentSubscription = this.service.contentChanged.subscribe(
      (content: Content) => {
        this.content = content;
      });

    this.service.fetchContentFromServer();
  }

  loadContentUrls(): void {
    this.contentUrls = this.service.getContentUrls();

    this.contentUrlsSubscription = this.service.contentUrlsChanged.subscribe(
      (contentUrls: ContentUrls) => {
        this.contentUrls = contentUrls;
      });

    this.service.fetchContentUrlsFromServer();
  }



}
