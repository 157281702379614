import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seperator',
  templateUrl: './seperator.component.html',
  styleUrls: ['./seperator.component.css']
})
export class SeperatorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
