export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCxD4rXgkF1UYp2P9rNhR-IEuOYKOphHE0",
    authDomain: "curls-studio.firebaseapp.com",
    databaseURL: "https://curls-studio.firebaseio.com",
    projectId: "curls-studio",
    storageBucket: "curls-studio.appspot.com",
    messagingSenderId: "699874865285",
    appId: "1:699874865285:web:7784f9befc70da523d79ec"
  }
};