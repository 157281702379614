import { Component, OnInit, Input } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { ContentService } from '../service/service.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bridal',
  templateUrl: './bridal.component.html',
  styleUrls: ['./bridal.component.css']
})
export class BridalComponent implements OnInit {
  @Input('content') content: any;

  bridalUrls;

  bridalUrlsSubscription: Subscription;


  constructor(public service: ContentService) { }

  ngOnInit() {

    this.bridalUrls = this.service.getBridalUrls();

    this.bridalUrlsSubscription = this.service.bridalUrlsChanged.subscribe(
      (bridalUrls: String[]) => {
        this.bridalUrls = bridalUrls;
        this.bridalUrls.sort((a, b) =>
          a.substr(
            (a.indexOf('%2F') + 3),
            a.indexOf('.jpg') - (a.indexOf('%2F') + 3)
          ) - b.substr(
            (b.indexOf('%2F') + 3),
            b.indexOf('.jpg') - (b.indexOf('%2F') + 3)
          )
        )
        setTimeout(function () { (<HTMLInputElement>document.getElementById("initSlidderOwlBride")).click(); }, 500);

      });

    this.service.fetchBridalUrlsFromServer();

  }

}
