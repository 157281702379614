import { Component, OnInit } from '@angular/core';
import { ContentService, homeSliderObject } from '../service/service.component';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.css']
})
export class HomeSliderComponent implements OnInit {

  homeSliderObjects;

  homeSliderObjectsSubscription: Subscription;

  homeSliderUrls;

  homeSliderUrlsSubscription: Subscription;

  constructor(public service: ContentService) { }

  ngOnInit() {
    //slidder OnInit
    // setTimeout(function () { (<HTMLInputElement>document.getElementById("initSlidder")).click(); }, 2000);


    // ############## Home sliders  objects ##############

    this.homeSliderObjects = this.service.getHomeSliders();

    this.homeSliderObjectsSubscription = this.service.homeSliderObjectsChanged.subscribe(
      (homeSliderObjects: homeSliderObject[]) => {
        this.homeSliderObjects = homeSliderObjects;
        this.homeSliderObjects.sort((a, b) => a.sort - b.sort);
        setTimeout(function () { (<HTMLInputElement>document.getElementById("sliderObjectTitle")).click(); }, 2000);
        setTimeout(function () { (<HTMLInputElement>document.getElementById("sliderObjectSubTitle")).click(); }, 2000);


      });

    this.service.fetchHomeSliderObjectsFromServer();

    // ############## Home sliders urls ##############

    this.homeSliderUrls = this.service.getHomeSliderUrls();

    this.homeSliderUrlsSubscription = this.service.homeSliderUrlsChanged.subscribe(
      (homeSliderUrls: string[]) => {
        this.homeSliderUrls = homeSliderUrls;
        setTimeout(function () { (<HTMLInputElement>document.getElementById("initSlidder")).click(); }, 500);

      });

    this.service.fetchHomeSliderUrlsFromServer();

  }

}
