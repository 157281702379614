import { Component, OnInit, Input } from '@angular/core';
import { ContentService, ContentUrls, TeamMember } from '../service/service.component';
import { Content } from '../service/service.component'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  @Input('content') content: any;
  @Input('contentUrls') contentUrls: any;
  // content;

  beforeAndAfterUrls;

  beforeAndAfterUrlsSubscription: Subscription;

  teamMembers;

  teamMembersSubscription: Subscription;

  constructor(public service: ContentService) { }

  ngOnInit() {
    this.loadBeforeAndAfter()
    this.loadTeamMembers()
  }

  loadBeforeAndAfter() {
    this.service.fetchBeforeAndAfterUrlsFromServer();
    this.beforeAndAfterUrls = this.service.getBeforeAndAfterUrls();

    this.beforeAndAfterUrlsSubscription = this.service.beforeAndAfterUrlsChanged.subscribe(
      (beforeAndAfterUrls: String[]) => {
        this.beforeAndAfterUrls = beforeAndAfterUrls;
        // console.log(beforeAndAfterUrls[0], beforeAndAfterUrls[0].substr((beforeAndAfterUrls[0].indexOf('%2F') + 3), beforeAndAfterUrls[0].indexOf('.jpg') - (beforeAndAfterUrls[0].indexOf('%2F') + 3)));

        this.beforeAndAfterUrls.sort((a, b) =>
          a.substr(
            (a.indexOf('%2F') + 3),
            a.indexOf('.jpg') - (a.indexOf('%2F') + 3)
          ) - b.substr(
            (b.indexOf('%2F') + 3),
            b.indexOf('.jpg') - (b.indexOf('%2F') + 3)
          )
        )
      });


  }

  loadTeamMembers() {
    this.teamMembers = this.service.getTeamMembers();

    this.teamMembersSubscription = this.service.teamMembersChanged.subscribe(
      (teamMembers: TeamMember[]) => {
        this.teamMembers = teamMembers;
        this.teamMembers.sort((a, b) =>
          a.sort - b.sort
        )
      });

    this.service.fetchTeamFromServer();
  }




}
