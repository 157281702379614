import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input('content') content: any;
  @Input('contentUrls') contentUrls: any;
  constructor() { }

  ngOnInit() {
    // console.log(this.content);

  }

  // https://search.google.com/local/writereview?placeid=ChIJPXmYaRcjWBQRUC5hLKTnq1k g8
  // ChIJJylsbCRBWBQRSgKQnAFoflA zayed

  // address
  // "Building 3 1st Floor Park St. El Sheikh Zayed"
  // name
  // "El Sheikh Zayed"
  // url
  // "https://maps.app.goo.gl/JiqAryu6GzLR3BqR8"
  // 1
  // address
  // "garden 8, Salah El Din Abdel Karim, First New Cairo, Cairo Governorate"
  // name
  // "New Cairo"
  // url
  // "https://www.google.com/maps/place/Garden+8/@30.0224643,31.4292856,13z/data=!4m8!1m2!2m1!1sFirst+New+Cairo+garden+8!3m4!1s0x0:0xf0dc244b32a6a3fc!8m2!3d30.0462132!4d31.4840806?hl=en"
}
