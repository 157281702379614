import { Component, OnInit, Input } from '@angular/core';
import { ContentService, Article } from '../service/service.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  @Input('content') content: any;
  @Input('contentUrls') contentUrls: any;

  articles;

  articlesSubscription: Subscription;

  constructor(public service: ContentService) { }
  getDate(element) {
    let unix_timestamp = element['seconds']
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    return new Date(unix_timestamp * 1000);
  }
  formatDate(date) {
    var today = this.getDate(date); //console.log(date);
    var res = "";
    res = today.toString();

    // this should be safe since nothing else in the date string contains a opening paren
    var index = res.indexOf("GMT");

    // if the index exists
    if (~index) {
      res = res.substr(0, index);
    }
    return res;
  }
  ngOnInit() {

    this.articles = this.service.getArticles();

    this.articlesSubscription = this.service.articlesChanged.subscribe(
      (articles: Article[]) => {
        this.articles = articles;
        this.articles.sort((a, b) =>
          a.sort - b.sort
        )

        setTimeout(function () { (<HTMLInputElement>document.getElementById("initSlidderOwlBlog")).click(); }, 2000);
      });

    this.service.fetchArticlesFromServer();


  }

}
