import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subject } from 'rxjs';
import { storage } from 'firebase';

export class Content {

  public aboutUsWhatWeDo: string;

  public aboutUsGivingHairNaturalLife: string;

  public galleryDescription: string;

  public bridalDescription: string;

  public kidsDescription: string;
  public phoneNumber: string;

  public email: string;

  public location: string;



  constructor(aboutUsWhatWeDo,
    aboutUsGivingHairNaturalLife,
    bridalDescription,
    kidsDescription,
    galleryDescription,
    phoneNumber,
    email,
    location) {
    this.aboutUsWhatWeDo = aboutUsWhatWeDo;
    this.aboutUsGivingHairNaturalLife = aboutUsGivingHairNaturalLife;
    this.bridalDescription = bridalDescription;
    this.kidsDescription = kidsDescription;

    this.galleryDescription = galleryDescription;
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.location = location;
  }

}

export class ContentUrls {

  aboutUs: string;

  article: string;

  constructor() { this.aboutUs = '', this.article = '' }
}


export class Article {

  title: string;
  date: string;
  description: string;
  articleUrl: string;


  constructor() { this.title = '', this.date = '', this.description = '', this.articleUrl = '' }
}

export class homeSliderObject {

  title: string;
  subTitle: string;


  constructor() { this.title = '', this.subTitle = '' }
}

export class TeamMember {

  name: string;
  instaLink: string;


  constructor() { this.name = '', this.instaLink = '' }
}


@Injectable({ providedIn: 'root' })

export class ContentService {

  // ###### Content ######

  content;

  contentChanged = new Subject<Content>();

  contentUrls: ContentUrls = new ContentUrls();

  contentUrlsChanged = new Subject<ContentUrls>();

  // ###### Gallery ######

  galleryUrls = [];

  galleryUrlsChanged = new Subject<String[]>();

  // ###### Bridal ######

  bridalUrls = [];

  bridalUrlsChanged = new Subject<String[]>();


  kidsUrls = [];

  kidsUrlsChanged = new Subject<String[]>();

  // ###### Celebrities ######

  celebritiesUrls = [];

  celebritiesUrlsChanged = new Subject<String[]>();

  // ###### Before And After ######

  beforeAndAfterUrls = [];

  beforeAndAfterUrlsChanged = new Subject<String[]>();

  // ###### Team ######

  teamMembers = [];

  teamMembersChanged = new Subject<TeamMember[]>();

  // ###### Home Slider ######

  articles = [];

  articlesChanged = new Subject<Article[]>();

  // ###### Home Slider ######

  homeSliderObjects = [];

  homeSliderObjectsChanged = new Subject<homeSliderObject[]>();

  homeSliderUrls = [];

  homeSliderUrlsChanged = new Subject<String[]>();
  galleryLength: number;
  celebLength: number;
  bridalLength: number;
  kidsLength: number;
  slidderLength: number;

  constructor(private storage: AngularFireStorage, private db: AngularFirestore) {
  }

  // ###################### GETTERS ######################


  // ---- Content ----

  getContent() {
    // Getter 
    return new Content('', '', '', '', '', '', '', '');
  }

  getContentUrls() {
    // Getter 
    return this.contentUrls;

  }

  // ---- Articles ----

  getArticles() {
    return this.articles;
  }


  // ---- HomeSlider ----


  getHomeSliders() {
    return this.homeSliderObjects;
  }


  getHomeSliderUrls() {
    return this.homeSliderUrls;
  }

  // ---- Team Members ----

  getTeamMembers() {
    return this.teamMembers;
  }


  // ---- Gallery ----

  getGalleryUrls() {
    return this.galleryUrls;
  }

  // ---- Bridal ----

  getBridalUrls() {
    return this.bridalUrls;
  }
  getKidsUrls() {
    return this.kidsUrls;
  }

  // ---- Celebs ----

  getCelebritiesUrls() {
    return this.celebritiesUrls;
  }


  // ---- Before And After ----

  getBeforeAndAfterUrls() {
    return this.beforeAndAfterUrls;
  }


  // ###################### SETTERS ######################

  // ---- Gallery ----

  addToGalleryUrls(url) {
    this.galleryUrls.push(url);
    if (this.galleryUrls.length == this.galleryLength)
      this.galleryUrlsChanged.next(this.galleryUrls.slice());
  }

  // ---- Bridal ----

  addToBridalUrls(url) {
    this.bridalUrls.push(url);
    if (this.bridalUrls.length == this.bridalLength)
      this.bridalUrlsChanged.next(this.bridalUrls.slice());
  }
  addToKidsUrls(url) {
    this.kidsUrls.push(url);
    if (this.kidsUrls.length == this.kidsLength)
      this.kidsUrlsChanged.next(this.kidsUrls.slice());
  }
  // ---- Celebrities ----

  addToCelebritiesUrls(url) {
    this.celebritiesUrls.push(url);

    if (this.celebritiesUrls.length == this.celebLength)
      this.celebritiesUrlsChanged.next(this.celebritiesUrls.slice());
  }


  // ---- Before And After ----

  addToBeforeAndAfterUrls(url) {
    this.beforeAndAfterUrls.push(url);
    this.beforeAndAfterUrlsChanged.next(this.beforeAndAfterUrls.slice());
  }

  // ---- Content ----

  addToContentUrls(imageName, url) {
    if (imageName.substring(0, imageName.indexOf('.'))
      .toLowerCase() == 'aboutus') { this.contentUrls.aboutUs = url; }
    if (imageName.substring(0, imageName.indexOf('.'))
      .toLowerCase() == 'article') { this.contentUrls.article = url; }
    this.contentUrlsChanged.next(this.contentUrls);
  }

  setContent(content: Content[]) {
    // Setter 
    this.content = content[0];
    this.contentChanged.next(this.content);
  }

  // ---- HomeSlider ----

  addToHomeSliderUrls(url) {
    this.homeSliderUrls.push(url);

    if (this.homeSliderUrls.length == this.slidderLength)
      this.homeSliderUrlsChanged.next(this.homeSliderUrls.slice());
  }

  setHomeSliderObjects(homeSliderObjects: homeSliderObject[]) {
    this.homeSliderObjects = homeSliderObjects;
    this.homeSliderObjectsChanged.next(this.homeSliderObjects.slice());
  }

  // ---- Team Members ----

  setTeamMembers(teamMembers: TeamMember[]) {
    this.teamMembers = teamMembers;
    this.teamMembersChanged.next(this.teamMembers.slice());
  }

  // ---- Articles ----

  setArticles(articles: Article[]) {
    this.articles = articles;
    this.articlesChanged.next(this.articles.slice());
  }

  // ###################### FETCH FROM SERVER ######################

  // ---- Gallery ----

  fetchGalleryUrlsFromServer() {
    var self = this;
    var storageRef = storage().ref();
    var listRef = storageRef.child('gallery');
    listRef.listAll().then(function (result) {
      self.galleryLength = result.items.length;
      result.items.forEach(function (imgRef) {
        imgRef.getDownloadURL().then(function (url) {
          self.addToGalleryUrls(url);
        });
      })
    }).catch(function (error) {
      console.log(error);
    });
  }

  // ---- Gallery ----

  fetchCelebritiesUrlsFromServer() {
    var self = this;
    var storageRef = storage().ref();
    var listRef = storageRef.child('celebrities');
    listRef.listAll().then(function (result) {

      self.celebLength = result.items.length;
      result.items.forEach(function (imgRef) {
        imgRef.getDownloadURL().then(function (url) {
          // console.log("HOLA CELEB")
          // console.log(url)
          self.addToCelebritiesUrls(url);
        });
      })
    }).catch(function (error) {
      console.log(error);
    });
  }

  // ---- Bridal ----

  fetchBridalUrlsFromServer() {
    var self = this;
    var storageRef = storage().ref();
    var listRef = storageRef.child('bridal');
    listRef.listAll().then(function (result) {

      self.bridalLength = result.items.length;
      result.items.forEach(function (imgRef) {
        imgRef.getDownloadURL().then(function (url) {
          self.addToBridalUrls(url);
        });
      })
    }).catch(function (error) {
      console.log(error);
    });
  }


  fetchKidsUrlsFromServer() {
    var self = this;
    var storageRef = storage().ref();
    var listRef = storageRef.child('kids');
    listRef.listAll().then(function (result) {

      self.kidsLength = result.items.length;
      result.items.forEach(function (imgRef) {
        imgRef.getDownloadURL().then(function (url) {
          self.addToKidsUrls(url);
        });
      })
    }).catch(function (error) {
      console.log(error);
    });
  }
  // ---- Before And After ----

  fetchBeforeAndAfterUrlsFromServer() {
    var self = this;
    var storageRef = storage().ref();
    var listRef = storageRef.child('beforeAndAfter');
    listRef.listAll().then(function (result) {
      result.items.forEach(function (imgRef) {
        imgRef.getDownloadURL().then(function (url) {
          self.addToBeforeAndAfterUrls(url);
        });
      })
    }).catch(function (error) {
      console.log(error);
    });
  }

  // ---- Content ----

  fetchContentFromServer() {
    this.db.collection<Content>('content').valueChanges().subscribe(
      res => {
        this.setContent(res)
      }
    )
  }
  // .E.g About us picture
  fetchContentUrlsFromServer() {
    var self = this;
    var storageRef = storage().ref();
    var listRef = storageRef.child('content');
    listRef.listAll().then(function (result) {
      result.items.forEach(function (imgRef) {
        imgRef.getDownloadURL().then(function (url) {
          self.addToContentUrls(imgRef.name, url);
        });
      })
    }).catch(function (error) {
      console.log(error);
    });
  }
  // ---- HomeSlider ----

  fetchHomeSliderUrlsFromServer() {
    var self = this;
    var storageRef = storage().ref();
    var listRef = storageRef.child('homeSliders');
    listRef.listAll().then(function (result) {
      self.slidderLength = result.items.length;
      result.items.forEach(function (imgRef) {

        imgRef.getDownloadURL().then(function (url) {
          self.addToHomeSliderUrls(url);
        });
      })
    }).catch(function (error) {
      console.log(error);
    });
  }

  fetchHomeSliderObjectsFromServer() {
    this.db.collection<homeSliderObject>('homeSliders').valueChanges().subscribe(
      res => {
        this.setHomeSliderObjects(res)
      }
    )
  }

  // ---- Team Members ----

  fetchTeamFromServer() {
    this.db.collection<TeamMember>('team').valueChanges().subscribe(
      res => {
        this.setTeamMembers(res)
      }
    )
  }

  // ---- Articles ----

  fetchArticlesFromServer() {
    this.db.collection<Article>('articles').valueChanges().subscribe(
      res => {
        this.setArticles(res)
      }
    )
  }

}
