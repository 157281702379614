import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ContentService } from './service/service.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { HeaderComponent } from './header/header.component';
import { HomeSliderComponent } from './home-slider/home-slider.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { SeperatorComponent } from './seperator/seperator.component';
import { AppoinmentComponent } from './appoinment/appoinment.component';
import { GallaryComponent } from './gallary/gallary.component';
import { WorkingHoursComponent } from './working-hours/working-hours.component';
import { FunFactsComponent } from './fun-facts/fun-facts.component';
import { TestimonilsComponent } from './testimonils/testimonils.component';
import { BlogComponent } from './blog/blog.component';
import { FooterComponent } from './footer/footer.component';
import { BridalComponent } from './bridal/bridal.component';
import { CelebritiesComponent } from './celebrities/celebrities.component';
import { KidsComponent } from './kids/kids.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    TopBarComponent,
    HeaderComponent,
    HomeSliderComponent,
    AboutUsComponent,
    SeperatorComponent,
    AppoinmentComponent,
    GallaryComponent,
    WorkingHoursComponent,
    FunFactsComponent,
    TestimonilsComponent,
    BlogComponent,
    FooterComponent,
    BridalComponent,
    CelebritiesComponent,
    KidsComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
  ],
  providers: [
    AngularFireStorage,
    ContentService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
