import { Component, Input, OnInit } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { ContentService } from '../service/service.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-celebrities',
  templateUrl: './celebrities.component.html',
  styleUrls: ['./celebrities.component.css']
})
export class CelebritiesComponent implements OnInit {

  @Input('content') content: any;
  celebritieslUrls;

  celebritieslUrlsSubscription: Subscription;


  constructor(public service: ContentService) { }

  ngOnInit() {


    this.celebritieslUrls = this.service.getCelebritiesUrls();

    this.celebritieslUrlsSubscription = this.service.celebritiesUrlsChanged.subscribe(
      (celebritieslUrls: String[]) => {
        // console.log("CELEBRITIESURLS!")
        // console.log(celebritieslUrls)
        this.celebritieslUrls = celebritieslUrls;
        this.celebritieslUrls.sort((a, b) =>
          a.substr(
            (a.indexOf('%2F') + 3),
            a.indexOf('.jpg') - (a.indexOf('%2F') + 3)
          ) - b.substr(
            (b.indexOf('%2F') + 3),
            b.indexOf('.jpg') - (b.indexOf('%2F') + 3)
          )
        )
        setTimeout(function () { (<HTMLInputElement>document.getElementById("initSlidderOwlCeleb")).click(); }, 500);

      });

    this.service.fetchCelebritiesUrlsFromServer();

  }

}

