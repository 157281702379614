import { Component, OnInit, Input } from '@angular/core';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { ContentService } from '../service/service.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-kids',
  templateUrl: './kids.component.html',
  styleUrls: ['./kids.component.css']
})
export class KidsComponent implements OnInit {
  @Input('content') content: any;

  kidsUrls;

  KidsUrlsSubscription: Subscription;


  constructor(public service: ContentService) { }

  ngOnInit() {

    this.kidsUrls = this.service.getKidsUrls();

    this.KidsUrlsSubscription = this.service.kidsUrlsChanged.subscribe(
      (KidsUrls: String[]) => {
        this.kidsUrls = KidsUrls;
        this.kidsUrls.sort((a, b) =>
          a.substr(
            (a.indexOf('%2F') + 3),
            a.indexOf('.jpg') - (a.indexOf('%2F') + 3)
          ) - b.substr(
            (b.indexOf('%2F') + 3),
            b.indexOf('.jpg') - (b.indexOf('%2F') + 3)
          )
        )
        setTimeout(function () { (<HTMLInputElement>document.getElementById("initSlidderOwlKids")).click(); }, 500);

      });

    this.service.fetchKidsUrlsFromServer();

  }

}

