import { Component, OnInit, Input } from '@angular/core';
import { ContentService, ContentUrls } from '../service/service.component';
import { Content } from '../service/service.component'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-gallary',
  templateUrl: './gallary.component.html',
  styleUrls: ['./gallary.component.css']
})
export class GallaryComponent implements OnInit {

  @Input('content') content: any;
  @Input('contentUrls') contentUrls: any;

  galleryUrls;

  galleryUrlsSubscription: Subscription;


  constructor(public contentService: ContentService) { }

  ngOnInit() {

    this.galleryUrls = this.contentService.getGalleryUrls();

    this.galleryUrlsSubscription = this.contentService.galleryUrlsChanged.subscribe(
      (galleryUrls: String[]) => {
        this.galleryUrls = galleryUrls;
        this.galleryUrls.sort((a, b) =>
          a.substr(
            (a.indexOf('%2F') + 3),
            a.indexOf('.jpg') - (a.indexOf('%2F') + 3)
          ) - b.substr(
            (b.indexOf('%2F') + 3),
            b.indexOf('.jpg') - (b.indexOf('%2F') + 3)
          )
        )
        setTimeout(function () { (<HTMLInputElement>document.getElementById("initSlidderOwlGallery")).click(); }, 500);
      });

    this.contentService.fetchGalleryUrlsFromServer();

  }



}
